import '@mapbox/search-js-web';
export { config } from '@mapbox/search-js-web';

export { AddressAutofill } from './components/AddressAutofill';
export { SearchBox } from './components/SearchBox';
export { AddressMinimap } from './components/AddressMinimap';

export { useEvented } from './hooks/useEvented';
export {
  useAddressAutofillCore,
  useAddressAutofillCore as useMapboxAutofill // alias for backward compatibility
} from './hooks/useAddressAutofillCore';
export { useSearchBoxCore } from './hooks/useSearchBoxCore';
export { useSearchSession } from './hooks/useSearchSession';
export { useConfirmAddress } from './hooks/useConfirmAddress';
